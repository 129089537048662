import React from 'react';
import Img from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { device } from '../lib/device';
import getAllCategories from '../hooks/getAllGalleries';
import Header from '../components/Header';
import Footer from '../components/Footer';

import '../style/app.scss';

const breakpointColumns = {
	default: 5,
	1100: 3,
	700: 2,
};

const Gallery = styled.div`
	@media ${device.desktopHhd} {
		margin: 20px;
	}
	.title {
		text-align: center;
		h1 {
			font-size: 60px;
			font-weight: var(--fw_light);
			font-family: var(--font_harmoniasanspro_light);
			margin-bottom: 0px;
			@media ${device.desktopHhd} {
				font-size: 40px;
			}
		}
		h2 {
			font-size: 30px;
			font-weight: var(--fw_light);
			font-family: var(--font_harmoniasanspro_light);
			color: var(--brand_blue);
			margin-top: 10px;
			margin-bottom: 40px;
			@media ${device.desktopHhd} {
				font-size: 20px;
			}
		}
	}
	ul {
		list-style-type: none;
		padding-left: 0;
		.masonry_grid {
			display: flex;
			width: auto;
			@media ${device.desktopHhd} {
				margin: 0;
			}
			.grid_column {
				padding-left: 30px;
				background-clip: padding-box;
				&:first-of-type {
					padding-left: 0;
				}
				li {
					transition: all 0.4s ease;
					margin-bottom: 20px;
					&:hover {
						transition: all 0.4s ease;
						h2 {
							opacity: 1;
						}
						.description {
							opacity: 1;
						}
					}
					a {
						text-decoration: none;
						color: var(--brand_blue);
					}
					h2 {
						opacity: 0;
						font-family: var(--font_harmoniasanspro_light);
						color: #303030;
						font-size: 14px;
						border-bottom: 1px solid #303030;
						margin-bottom: 10px;
						padding-bottom: 10px;
						@media ${device.tablet} {
							opacity: 1;
						}
					}
					.description {
						opacity: 0;
						color: var(--brand_black);
						font-size: 14px;
						font-family: var(--font_harmoniasanspro_light);
						font-weight: 400;
						@media ${device.tablet} {
							opacity: 1;
						}
					}
				}
			}
		}
	}
`;

const Categories = styled.div`
	ul {
		padding: 0;
		margin: 0;
		display: flex;
		gap: 10px;
		@media ${device.desktop} {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		li {
			a {
				font-family: var(--font_harmoniasanspro_light);
				border: 1px solid var(--brand_blue);
				padding: 5px 10px;
				text-decoration: none;
				color: var(--brand_blue);
				display: inline-block;
				transition: all 0.4s ease;
				&:hover {
					background-color: var(--brand_blue);
					color: white;
				}
			}
		}
	}
`;

const GallerySingle = (data) => {
	const { wpCategory } = data.data;

	return (
		<>
			<Header />
			<Gallery>
				<div className="container">
					<div className="title">
						<h1>{wpCategory.name}</h1>
						<h2>{wpCategory.description}</h2>
					</div>
					<Categories>
						<ul>
							<li>
								<Link to="/gallery">Alle gallerier</Link>
							</li>
							{wpCategory.wpChildren.nodes.map((children, i) => (
								<li>
									<Link to={`/gallery/${children.slug}`}>{children.name}</Link>
								</li>
							))}
						</ul>
					</Categories>
					<ul>
						<Masonry
							breakpointCols={breakpointColumns}
							className="masonry_grid"
							columnClassName="grid_column"
						>
							{wpCategory.paintings.nodes.map((painting, i) => (
								<li key={painting.id}>
									<Link to={`/painting/${painting.slug}`}>
										<Img fluid={painting.featuredImage?.node.localFile.childImageSharp.fluid} />
										<h2>{painting.title}</h2>
									</Link>
								</li>
							))}
						</Masonry>
					</ul>
				</div>
			</Gallery>
			<Footer />
		</>
	);
};

export const pageQuery = graphql`
	query($id: String!) {
		wpCategory(id: { eq: $id }) {
			name
			description
			slug
			acf {
				featuredImage {
					localFile {
						childImageSharp {
							fixed {
								...GatsbyImageSharpFixed
							}
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			paintings {
				nodes {
					slug
					title
					id
					featuredImage {
						node {
							localFile {
								childImageSharp {
									fixed {
										...GatsbyImageSharpFixed
									}
									fluid {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
			wpParent {
				node {
					slug
					id
					name
				}
			}
			wpChildren {
				nodes {
					slug
					id
					name
				}
			}
		}
	}
`;

export default GallerySingle;
