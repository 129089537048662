import React from 'react';
import { Link } from 'gatsby';
import '../style/app.scss';
import styled from 'styled-components';
import { device } from '../lib/device';
import IconInstagram from './svg/IconInstagram';
import IconFacebook from './svg/IconFacebook';
import IconPinterest from './svg/IconPinterest';

const FooterWrap = styled.footer`
	border-top: 1px solid var(--brand_black);
	width: 100%;
	position: relative;
	margin-bottom: 20px;
	.wrap_items {
		text-align: center;
		margin: 0 auto;
		.social_icons {
			display: block;
			ul {
				list-style-type: none;
				padding-left: 0;
				display: inline-flex;
				margin-top: 20px;
				li {
					margin-right: 15px;
					svg {
						width: 30px;
						height: 30px;
						@media ${device.mobile} {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}
	}
	.all_rights {
		margin: 0 auto;
		text-align: center;
		font-family: var(--font_harmoniasanspro_light);
		color: var(--brand_black);
		font-weight: 100;
		font-size: 14px;
	}
`;

const Footer = () => (
	<FooterWrap>
		<div className="wrap_items">
			<div className="social_icons">
				<ul>
					<li className="pinterest">
						<a target="_blanc" href="https://pinterest.com">
							<IconPinterest />
						</a>
					</li>
					<li className="instagram">
						<a target="_blanc" href="https://instagram.com">
							<IconInstagram />
						</a>
					</li>
					<li className="facebook">
						<a target="_blanc" href="https://facebook.com">
							<IconFacebook />
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div className="all_rights">Marianne Hesle 2021. All Rights Reserved</div>
	</FooterWrap>
);

export default Footer;
